import './App.css';
import MainNavbar from './components/MainNavbar';

// TO DO: 
// - styling

function App() {
  return (
    <div className="App">
      <MainNavbar/>
    </div>
  );
}

export default App;
